<template>
  <div class="body">
    <div class="body-cnt">
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom:10px">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="18">
            <el-button type="primary" @click="newBtn">新建</el-button>
          </el-col>
          <el-col :span="6">
            <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
              <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>

      <!-- 表格部分 -->
      <el-table border ref="table" :data="tableData" tooltip-effect="dark"
        class="scroll-table"
        style="width: 100%" :height="height" max-height="78vh">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>

          <!-- <el-table-column label="" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column> -->

          <el-table-column label="电站名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationName }}</template>
          </el-table-column>
          <el-table-column label="电站简称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationAliasName }}</template>
          </el-table-column>
          <el-table-column label="电站时区" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.timeZone }}</template>
          </el-table-column>
          <el-table-column label="电站地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.address }}</template>
          </el-table-column>
          <el-table-column label="经度" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.longitude }}</template>
          </el-table-column>
          <el-table-column label="纬度" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.latitude }}</template>
          </el-table-column>
          <el-table-column label="电站负责人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.heador }}</template>
          </el-table-column>
          <el-table-column label="联系人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.linkMan }}</template>
          </el-table-column>
          <el-table-column label="联系电话" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.linkPhone }}</template>
          </el-table-column>
          <el-table-column label="电站类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.stationType }}</template>
          </el-table-column>
          <el-table-column label="已投产容量（KWp）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.volume }}</template>
          </el-table-column>
          <el-table-column label="并网时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.onGridTime }}</template>
          </el-table-column>
          <el-table-column label="并网类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.onGridType }}</template>
          </el-table-column>
          <el-table-column label="厂站规划" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.stationPlant }}</template>
          </el-table-column>
          <el-table-column label="安全生产开始时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.runStartTime }}</template>
          </el-table-column>
          <el-table-column label="数据来源" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.dataSources }}</template>
          </el-table-column>
          <el-table-column label="厂站图片" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.logo }}</template>
          </el-table-column>
          <el-table-column label="电站简介" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationIntro }}</template>
          </el-table-column>
          <el-table-column label="统一社会信用代码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.creditCode }}</template>
          </el-table-column>
          <el-table-column label="海拔" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.altitude }}</template>
          </el-table-column>
          <el-table-column label="当前功率（KW）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.currEfficiency }}</template>
          </el-table-column>
          <el-table-column label="当日发电量（kWh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.currDayPowerNum }}</template>
          </el-table-column>
          <el-table-column label="本月发电量（kWh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.monthPowerNum }}</template>
          </el-table-column>
          <el-table-column label="本年发电量（kWh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.yearPowerNum }}</template>
          </el-table-column>
          <el-table-column label="累计发电量（kWh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.totalPowerNum }}</template>
          </el-table-column>
          <el-table-column label="当日收益（元）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.currDayIncome }}</template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createTime }}</template>
          </el-table-column>
          <el-table-column label="修改时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateTime }}</template>
          </el-table-column>
          <el-table-column label="供电方式" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.supplyPowerMode }}</template>
          </el-table-column>
          <el-table-column label="光伏并网模式" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.onGridMode }}</template>
          </el-table-column>
          <el-table-column label="电站状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationStatus }}</template>
          </el-table-column>
          <el-table-column label="排序" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.seq }}</template>
          </el-table-column>
          <el-table-column label="中控电话" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.centralControlPhone }}</template>
          </el-table-column>
          <el-table-column label="反射率（0-1）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.reflectivity }}</template>
          </el-table-column>
          <el-table-column label="设计装机容量（kW）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.designVolume }}</template>
          </el-table-column>
          <el-table-column label="每度电价" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.electricityPrice }}</template>
          </el-table-column>

          <el-table-column label="省份名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.provinceName }}</template>
          </el-table-column>
          <el-table-column label="所属资源区" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.resourceArea }}</template>
          </el-table-column>
          <el-table-column label="省内每月平均弃光电量（kWh）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.abandonedSolarPower }}</template>
          </el-table-column>
          <el-table-column label="功率预测平均值（%）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerPredictionAverage }}</template>
          </el-table-column>
          <el-table-column label="电站发电量计算类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.powerStationCalculationType }}</template>
          </el-table-column>

          <el-table-column label="创建人名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createName }}</template>
          </el-table-column>

          <el-table-column label="修改人名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateName }}</template>
          </el-table-column>

          <el-table-column label="公司名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.deptName }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @onConfirm="handleDelte(scope.row.id)">
              <el-button slot="reference" class="textred" style="margin: 0 5px;" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          class="pagination-style"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">

            <!-- <el-col :span="12">
              <el-form-item label="：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col> -->
   
            <el-col :span="12">
              <el-form-item label="电站名称：" prop="siteName">
                <el-input v-model="form.powerStationName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站简称：" prop="siteName">
                <el-input v-model="form.powerStationAliasName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站时区：" prop="siteName">
                <el-input v-model="form.timeZone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站地址：" prop="siteName">
                <el-input v-model="form.address" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度：" prop="siteName">
                <el-input v-model="form.longitude" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度：" prop="siteName">
                <el-input v-model="form.latitude" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站负责人：" prop="siteName">
                <el-input v-model="form.heador" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人：" prop="siteName">
                <el-input v-model="form.linkMan" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="siteName">
                <el-input v-model="form.linkPhone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站类型：" prop="siteName">
                <el-input v-model="form.stationType" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已投产容量（KWp）：" prop="siteName">
                <el-input v-model="form.volume" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="并网时间：" prop="siteName">
                <el-input v-model="form.onGridTime" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="并网类型：" prop="siteName">
                <el-input v-model="form.onGridType" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="厂站规划：" prop="siteName">
                <el-input v-model="form.stationPlant" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安全生产开始时间：" prop="siteName">
                <el-input v-model="form.runStartTime" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数据来源：" prop="siteName">
                <el-input v-model="form.dataSources" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="厂站图片：" prop="siteName">
                <el-input v-model="form.logo" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站简介：" prop="siteName">
                <el-input v-model="form.powerStationIntro" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码：" prop="siteName">
                <el-input v-model="form.creditCode" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="海拔：" prop="siteName">
                <el-input v-model="form.altitude" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="当前功率（KW）：" prop="siteName">
                <el-input v-model="form.currEfficiency" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="当日发电量（kWh）：" prop="siteName">
                <el-input v-model="form.currDayPowerNum" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本月发电量（kWh）：" prop="siteName">
                <el-input v-model="form.monthPowerNum" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="本年发电量（kWh）：" prop="siteName">
                <el-input v-model="form.yearPowerNum" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="累计发电量（kWh）：" prop="siteName">
                <el-input v-model="form.totalPowerNum" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="当日收益（元）：" prop="siteName">
                <el-input v-model="form.currDayIncome" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="siteName">
                <el-input v-model="form.createTime" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="修改时间：" prop="siteName">
                <el-input v-model="form.updateTime" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="供电方式：" prop="siteName">
                <el-input v-model="form.supplyPowerMode" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="光伏并网模式：" prop="siteName">
                <el-input v-model="form.onGridMode" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站状态：" prop="siteName">
                <el-input v-model="form.powerStationStatus" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序：" prop="siteName">
                <el-input v-model="form.seq" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="中控电话：" prop="siteName">
                <el-input v-model="form.centralControlPhone" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="反射率（0-1）：" prop="siteName">
                <el-input v-model="form.reflectivity" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设计装机容量（kW）：" prop="siteName">
                <el-input v-model="form.designVolume" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="每度电价：" prop="siteName">
                <el-input v-model="form.electricityPrice" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="省份名称：" prop="siteName">
                <el-input v-model="form.provinceName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属资源区：" prop="siteName">
                <el-input v-model="form.resourceArea" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="省内每月平均弃光电量（kWh）：" prop="siteName">
                <el-input v-model="form.abandonedSolarPower" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="功率预测平均值（%）：" prop="siteName">
                <el-input v-model="form.powerPredictionAverage" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电站发电量计算类型：" prop="siteName">
                <el-input v-model="form.powerStationCalculationType" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="创建人名称：" prop="siteName">
                <el-input v-model="form.createName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :span="12">
              <el-form-item label="修改人名称：" prop="siteName">
                <el-input v-model="form.updateName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :span="12">
              <el-form-item label="公司名称：" prop="siteName">
                <el-input v-model="form.deptName" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0
    };
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 170;
    },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/powerStation/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave () {
	    var that = this;
	  that.$http.post("/powerStation/save", that.form).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })
	  
	  
    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
//    handleDelte (id) {
//      del({ id: id }).then(res => {
//        if (res.code == 200) {
 //         this.$message.success('删除成功')
 //         this.getList()
 //       }
//      })
//    },
	
	// 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
           var that = this;
        that.$http.post("/powerStation/delete" ,{"id":id}).then(function (response) {
			if(response.data.code == 200){
			 that.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  that.getList();
			}else{
				 that.$notify.info({
					title: "提示",
					message: response.data.message,
					showClose: true,
				  });
			}
         
        });
      }
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {}
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style scoped lang="scss">
.body{
    background: #223f6c;
    height: 100%;
    padding: 10px;
    ::v-deep .el-input-group__append{
      background: #409EFF;
      color: #fff;
    }
  ::v-deep .body-cnt .el-input__inner{
    border: 1px solid rgba(151, 179, 203, 0.55);
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #f2f2f2;
  }
}
/deep/ .el-table--border, .el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
}
    /deep/ .el-table--border th{
        background-color: #21527e;

     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
        
    /deep/ .el-table th{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
    /deep/ .el-table td{
        background-color: #2d5981;
     border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    }
  /deep/ .el-table__body-wrapper{
    background: #2d5981;
  }
  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td, /deep/.el-table__body tr.hover-row>td {
  background-color: #223f6c !important;
}
  /deep/ .el-pagination{
    display: flex;
    justify-content: flex-end;
  }
    /deep/ .el-pagination button:disabled{
    background: rgba(255, 255, 255, 0);
  }
  /deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
    }
    /deep/ .el-pager li{
        background: none;
        color: #f2f2f2;
    }
    /deep/ .el-icon{
        color: #fff;
    }
    
    /deep/ .el-pagination .btn-prev{
        background: none;
    }
    /deep/ .el-pagination .btn-next{
        background: none;
    }
  /deep/ .el-pagination__total{
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
  }
 /deep/ .el-pagination__jump{  
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
  }
</style>

