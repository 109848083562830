import { render, staticRenderFns } from "./PowerStation.vue?vue&type=template&id=67104891&scoped=true"
import script from "./PowerStation.vue?vue&type=script&lang=js"
export * from "./PowerStation.vue?vue&type=script&lang=js"
import style0 from "./PowerStation.vue?vue&type=style&index=0&id=67104891&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67104891",
  null
  
)

component.options.__file = "PowerStation.vue"
export default component.exports